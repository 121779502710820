class Offcanvas {
	constructor() {
		const OFFCANVAS = document.getElementById('offcanvas');
		let offcanvasToggle = document.getElementsByClassName('offcanvas-icon');

		if(OFFCANVAS && offcanvasToggle.length > 0) {
			this.init(offcanvasToggle, OFFCANVAS);
		}
	}

	init(offcanvasToggle, OFFCANVAS) {
		for(let i = 0; i < offcanvasToggle.length; i++) {
			let toggle = offcanvasToggle[i];
			toggle.addEventListener('click', ()=>{
				toggle.classList.toggle('active');
				OFFCANVAS.classList.toggle('active');
			})
		}
	}
}
