document.addEventListener("scroll", parallax);

function parallax() {
	this.querySelectorAll(".parallax").forEach((item) => {
		let posY = 0;
		if(window.innerWidth >= 1440) {
			posY = Math.round(-window.scrollY * 0.1);
		} else {
			posY = 0;
		}
		item.style.transform = `translate3d(0, ${posY}px, 0)`;
	});
}
