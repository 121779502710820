class Accordion {
	constructor() {
		const ACCORDIONS = document.getElementsByClassName('accordion');
		if(ACCORDIONS.length > 0) {
			for(let i = 0; i < ACCORDIONS.length; i++) {
				this.init(ACCORDIONS[i]);
			}

			window.addEventListener('resize', ()=> {
				for(let i = 0; i < ACCORDIONS.length; i++) {
					this.resize(ACCORDIONS[i]);
				}
			});
		}
	}

	resize(accordion) {
		const articles = accordion.getElementsByTagName('article');

		for(let i = 0; i < articles.length; i++) {
			let article = articles[i];
			let content = article.getElementsByClassName('content')[0];
			content.setAttribute('og-height', content.scrollHeight );
			if(article.classList.contains('active')) {
				content.style.height = 'auto';
			}

		}
	}

	init(accordion) {
		const articles = accordion.getElementsByTagName('article');

		for(let i = 0; i < articles.length; i++) {
			let article = articles[i];
			let header = article.getElementsByTagName('header')[0];
			let content = article.getElementsByClassName('content')[0];
			content.setAttribute('og-height', content.scrollHeight);
			content.style.height = content.getAttribute('og-height') + 'px';

			if(i != 0) {
				content.style.height = '0px';
			} else {
				article.classList.add('active');
			}

			header.addEventListener('click', ()=>{
				/*for(let i = 0; i < articles.length; i++) {
					articles[i].classList.remove('active');
					articles[i].getElementsByClassName('content')[0].style.height = '0px';
				}*/
				article.classList.toggle('active');
				if(article.classList.contains('active')){
					content.style.height = content.getAttribute('og-height') + 'px';
				} else {
					content.style.height = '0px';
				}

			});
		}
	}
}
